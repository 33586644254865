import React from 'react';

function Layout(props) {
  return (
    <div
      className='h-full w-full'
      {...props}
    >
      {props.children}
    </div>
  );
}

export default Layout;
