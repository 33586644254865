"use client";
import React from "react";

import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";

export const IconContainer = ({ icon, title, delay }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        scale: 0.95,
      }}
      whileInView={{
        opacity: 1,
        scale: 1,
      }}
      transition={{
        duration: 0.2,
        delay: delay ? delay : 0,
      }}
      // viewport={{ once: true }}
      className={twMerge(
        "z-50 flex flex-col items-center justify-center space-y-2"
      )}
    >
      <div className="flex items-center justify-center rounded-lg border border-!grey-300 bg-!black shadow-inner p-2">
        {icon}
      </div>
      <div className="rounded-md px-2 py-1 md:block">
        <div className="text-center lg:text-[0.9rem] md:text-[0.8rem] text-[0.7rem] text-!grey-200">
          {title}
        </div>
      </div>
    </motion.div>
  );
};

