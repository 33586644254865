import React from 'react';

function BgGradientText(props) {
  return (
    <div className="relative flex flex-col sm:flex-row mx-auto">
      <span className="absolute h-full w-full z-10 bg-!gradient-4 bg-cover blur-xl"></span>
      <h1 className="relative z-20 text-white text-center sm:text-left">{props.text}</h1>
    </div>
  );
}


export default BgGradientText;