import React from 'react';
import {twMerge} from "tailwind-merge";

function PlanContainer(props) {
  return (
    <div className={
      twMerge(
        'flex flex-col border-[0px] border-!grey-300 bg-!grey-300/20 rounded-xl lg:h-[400px] md:h-[380px] h-[300px] w-full lg:p-6 md:p-5 p-4 gap-y-6 mx-auto group',
        props.className
      )
    }>
      <div className='flex flex-col w-full z-10 gap-y-2'>
        {/* Name Section */}
        <div className='flex flex-row lg:text-[1.2rem] md:text-[1rem] text-[0.95rem] text-white/70'>
          {props.name}
        </div>

        {/* Price Section */}
        <div className='flex flex-row lg:text-[2.5rem] md:text-[2rem] text-[1.6rem] text-white/80 font-semibold leading-tight'>
          {props.price === 0 ? (
            <span className='flex flex-row items-baseline'>
              <span>{'$' + props.price}</span>
              <span className='flex lg:text-[0.8rem] md:text-[0.7rem] text-[0.6rem] font-light items-baseline'>&nbsp;for starting&nbsp;</span>
            </span>
          ) : props.price > 0 ? (
            <span className='flex flex-row items-baseline'>
              <span>{'$' + props.price}</span>
              <span className='flex lg:text-[0.8rem] md:text-[0.7rem] text-[0.6rem] font-light items-baseline'>{' / ' + props.interval}</span>
            </span>
          ) : (
            'Custom'
          )}
        </div>

        {/* Description Section */}
        <div className='flex flex-row lg:text-[1rem] md:text-[0.85rem] text-[0.7rem] text-white/60'>
          {props.description}
        </div>

        {/* Action Section */}
        <div className='flex flex-row md:py-4 py-3'>
          {props.action}
        </div>

        {/* Divider */}
        <div className='flex flex-col w-full border-t-[1px] border-white/20 mx-auto' />
      </div>

      {/* Features Section */}
      <div className='flex flex-col gap-y-2 text-white/60'>
        {props.features.map((feature, index) => (
          <div className='flex flex-row gap-x-2' key={index}>
            <span className='flex'>
              <img className='lg:h-[1rem] md:h-[0.85rem] h-[0.7rem]' src='/media/images/illustrations/check-gradient.webp' alt='bullet' />
            </span>
            <span className='flex lg:text-[0.85rem] md:text-[0.75rem] text-[0.7rem] my-auto'>{feature}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlanContainer;