import React, {useEffect, useState} from 'react';
import BgGradientButton from "../components/BgGradientButton";
import GradientButton from "../components/GradientButton";
import GradientText from "../components/GradientText";
import BgGradientText from "../components/BgGradientText";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";
import {WAITLIST_CREATE_RESET} from "../store/waitlist/waitlistConstants";
import {UTILS} from "../commons/utils";
import {create as joinWaitlist} from "../store/waitlist/waitlistActions";
import Loader from "../components/Loader";
import ContentContainer from "../components/ContentContainer";
import GrayGradientText from "../components/GrayGradientText";
import {DATA} from "../commons/data";
import PlanContainer from "../components/PlanContainer";
import {BackgroundCellCore} from "../components/BackgroundCellCore";
import {BackgroundBeams} from "../components/BackgroundBeams";
import {Badge} from "../components/Badge";
import {AuroraBackground} from "../components/AuroraBackground";
import {Radar} from "../components/Radar";
import {IconContainer} from "../components/IconContainer";
import ScrollCarousel from "../components/ScrollCarousel";

import {TransitionDiv} from "../components/Div";
import {useNavigate} from "react-router-dom";

function WaitlistForm(props) {
  const dispatch = useDispatch();

  const createdWaitlist = useSelector(state => state.createWaitlist);
  const { loading, waitlist, error } = createdWaitlist;

  useEffect(() => {
    if(waitlist) {
      toast.success('Successfully added to waitlist!');

      dispatch({
        type: WAITLIST_CREATE_RESET
      })
    }
    else if(error) {
      toast.error(error.message);
    }
  }, [waitlist, error]);


  const onWaitlistSubmit = (e) => {
    e.preventDefault();
    let data = UTILS.buildJsonFromForm(e.target);

    dispatch(joinWaitlist(data));
    e.target.reset();
  }

  return (
    <div className="flex mx-auto w-full flex-col px-10 py-6 text-!grey-200 text-[0.8rem]">
      {/*<h2 className="title-font mb-1 font-bold md:text-[1.5rem] text-[1.2rem]">Waitlist</h2>*/}
      {/*<p className="mb-5 leading-relaxed">Join the waitlist to help us test our beta. And also be the first to know when we release.</p>*/}

      <form className='flex flex-col gap-y-2 w-full' onSubmit={onWaitlistSubmit}>
        <input
          type="text"
          name='name'
          placeholder="Full Name"
          className="flex flex-row w-full rounded-lg border-[2px] border-!grey-400 focus:ring-2 focus:ring-!purple-500 outline-none px-4 py-2  bg-transparent placeholder:text-!grey-200/60 text-[0.85rem]"
        />

        <div className='flex flex-row w-full gap-x-2'>
          <input
            type="email"
            name='email'
            placeholder="you@email.com"
            className="flex flex-col w-full rounded-lg border-[2px] border-!grey-400 focus:ring-2 focus:ring-!green-500 outline-none px-4 py-2  bg-transparent placeholder:text-!grey-200/60 text-[0.85rem]"
          />

          <div className='flex flex-col'>
            <GradientButton type='submit' text={ loading ? (
              <span className='flex flex-row w-full h-full m-auto justify-center py-[0.1rem]'>
              <Loader className='md:h-[0.9rem] h-[0.6rem]' />
            </span>
              ) : <span className='font-bold'>&rarr;</span> } />
          </div>
        </div>
      </form>
      <p className="mt-3 text-xs">Feel free to connect with us on social media platforms.</p>
    </div>
  )
}

function Hero(props) {
  return (
    <div className='flex flex-col w-full'>
      <div className='flex z-10'>
        <BackgroundCellCore />
      </div>

      <div className='flex flex-col w-full my-[8vh] md:my-[12vh] lg:my-[16vh] gap-y-8 z-40'>
        <div className='flex flex-row mx-auto w-fit mb-2'>
          <Badge text="✨ Try our beta now" link='/signup' />
        </div>

        <div className='flex flex-col mx-auto text-white xl:text-6xl lg:text-5xl md:text-4xl text-3xl font-semibold sm:gap-y-2 justify-center z-50 w-fit text-center'>
          <div className='flex sm:flex-row flex-col justify-center'>
            <h1 className='text-!off-white'>Elevate Your&nbsp;</h1>
            <BgGradientText text='Interview' />
            <h1 className='text-!off-white'>&nbsp;Skills with our&nbsp;</h1>
          </div>

          <div className='flex sm:flex-row flex-col justify-center sm:text-center sm:justify-center '>
            <h1 className='text-!off-white'>AI-Powered&nbsp;</h1>
            <GradientText text='Interviewer' />
          </div>
        </div>

        <div className='flex flex-row lg:w-[40%] w-[80%] md:w-[60%] text-center mx-auto lg:text-[1rem] md:text-[0.9rem] text-[0.8rem] text-!grey-200 leading-5'>
          Conduct or practice realistic, end-to-end interviews effortlessly and gain actionable insights to make better decisions.
        </div>

        <div className='flex flex-col w-[90%] md:w-[80%] lg:w-[70%] mx-auto'>
          <img src={'/media/images/illustrations/product-mockup-xdr.webp'} loading='lazy' alt='product mockup' />
        </div>
      </div>
    </div>
  );
}

function Platform(props) {

  return (
    <div className='relative flex flex-col sm:w-[80%] w-full  mx-auto gap-y-10'>
      <div className='flex flex-col mx-auto gap-y-4 text-center'>
        <div className='flex flex-row font-semibold mx-auto lg:text-5xl md:text-4xl text-3xl leading-loose text-center'>
          <GrayGradientText text='Supercharge Interviews' />
        </div>

        <div className='flex flex-row lg:text-[1rem] md:text-[0.9rem] text-[0.8rem] text-!grey-200 justify-center text-center lg:leading-5 leading-4  mx-auto'>
          A dedicated platform to conduct interviews with ease
        </div>
      </div>


      <div className='flex flex-col w-[90%] md:w-[80%] lg:w-[70%] mx-auto'>
        <img src={'/media/images/illustrations/product-mac-mockup.webp'} loading='lazy' alt='product mock' />
      </div>
    </div>
  )
}


function Roles(props) {
  const roles = DATA.roles;

  return (
    <div className='flex flex-col w-[90%] md:w-[80%] lg:w-[70%] mx-auto gap-y-10 rounded-lg bg-gradient-to-br from-!off-white/5 via-!grey-200/5 to-!grey-250/5  py-12'
    >
      <div className='flex flex-col mx-auto gap-y-4'>
        <div className='flex flex-row font-semibold mx-auto lg:text-5xl md:text-4xl text-3xl leading-loose'>
          <GrayGradientText text='Roles' />
        </div>

        <div className='flex flex-row lg:text-[1rem] md:text-[0.9rem] text-[0.8rem] text-!grey-200 justify-center text-center lg:leading-5 leading-4  mx-auto'>
          Conduct or practise interview for the following roles
        </div>
      </div>


      <div className='flex flex-grow w-[80%] mx-auto'>
        {
          roles.map((role, index) => (
            <ContentContainer key={index} id={role.label} {...role} />
          ))
        }
      </div>


      <div className='flex flex-col mx-auto gap-y-4'>
        <div className='flex flex-row lg:text-[1rem] md:text-[0.9rem] text-[0.8rem] text-!grey-200 justify-center text-center lg:leading-5 leading-4  mx-auto'>
          and more, coming soon
        </div>
      </div>
    </div>
  )
}

function Feature(props) {
  const features = DATA.features;

  return (
    <div className='flex flex-col w-full mx-auto gap-y-10'>
      <div className='flex flex-col mx-auto gap-y-4'>
        <div className='flex flex-row font-semibold mx-auto lg:text-5xl md:text-4xl text-3xl leading-loose'>
          <GrayGradientText text='Features' />
        </div>
      </div>


      <div className='flex flex-col h-full w-full m-auto justify-center lg:gap-y-28 gap-y-20 relative py-32'>
        <div className="flex w-full items-center justify-center lg:gap-x-14 gap-x-10 ">
          <IconContainer
            delay={0.2}
            title={features[0].title}
            icon={
              <img className='flex lg:h-12 lg:w-12 md:h-10 md:w-10 h-8 w-8 opacity-60 p-2' src={features[0].src} alt={features[0].label} />
            }
          />

          <IconContainer
            delay={0.4}
            title={features[1].title}
            icon={
              <img className='flex lg:h-12 lg:w-12 md:h-10 md:w-10 h-8 w-8 opacity-60 p-2' src={features[1].src} alt={features[1].label} />
            }
          />
        </div>

        <div className="flex w-full items-center justify-center lg:gap-x-24 gap-x-20">
          <IconContainer
            delay={0.3}
            title={features[2].title}
            icon={
              <img className='flex lg:h-12 lg:w-12 md:h-10 md:w-10 h-8 w-8 opacity-60 p-2' src={features[2].src} alt={features[2].label} />
            }
          />

          <IconContainer
            delay={0.4}
            title={features[3].title}
            icon={
              <img className='flex lg:h-12 lg:w-12 md:h-10 md:w-10 h-8 w-8 opacity-60 p-2' src={features[3].src} alt={features[3].label} />
            }
          />

          <IconContainer
            delay={0.5}
            title={features[4].title}
            icon={
              <img className='flex lg:h-12 lg:w-12 md:h-10 md:w-10 h-8 w-8 opacity-60 p-2' src={features[4].src} alt={features[4].label} />
            }
          />
        </div>

        <div className="flex w-full items-center justify-center lg:gap-x-10 gap-x-8 ">
          <IconContainer
            delay={0.4}
            title={features[6].title}
            icon={
              <img className='flex lg:h-12 lg:w-12 md:h-10 md:w-10 h-8 w-8 opacity-60 p-2' src={features[6].src} alt={features[6].label} />
            }
          />

          <IconContainer
            delay={0.3}
            title={features[7].title}
            icon={
              <img className='flex lg:h-12 lg:w-12 md:h-10 md:w-10 h-8 w-8 opacity-60 p-2' src={features[7].src} alt={features[7].label} />
            }
          />
        </div>

        <Radar className='absolute overflow-hidden' />
      </div>
    </div>
  )
}

function Product(props) {
  const steps = DATA.steps;

  return (
    <div className='relative flex flex-col w-full mx-auto gap-y-20'>
      <div className='flex flex-row w-[80%] sm:text-5xl text-4xl font-Inter font-bold mx-auto justify-center'>
        <GrayGradientText text='How it works' />
      </div>

      {/*<div className='flex flex-col w-[80%] mx-auto'>*/}
      {/*  <img src={'/media/images/illustrations/how-interviewer-works.webp'} loading='lazy' alt='how-interviewer-works' />*/}
      {/*</div>*/}

      <ScrollCarousel cards={steps} />
    </div>
  )
}

function Plans(props) {
  const plans = DATA.plans;

  const navigate = useNavigate();

  return (
    <div className='flex flex-col lg:w-[80%] w-[90%] mx-auto gap-y-10'>
      <div className='flex flex-col mx-auto gap-y-4'>
        <div className='flex flex-row font-semibold mx-auto lg:text-5xl md:text-4xl text-3xl leading-loose text-center'>
          <GrayGradientText text='Pricing*' />
        </div>
      </div>


      <div className='grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 w-full mx-auto gap-4 justify-evenly'>
        {
          plans.map((plan, index) => (
            <PlanContainer
              key={index}
              id={plan.id}
              // className='lg:h-[150px] md:h-[100px] h-[50px] lg:w-[150px] md:w-[100px] w-[50px]'
              {...plan}
              action={
                plan.id === 'free' ? (
                  <BgGradientButton text='Get Started' onClick={() => navigate('/signup')} />
                ) : (
                  <button className="h-full w-full rounded-md md:px-8 px-4 py-2 md:text-[0.9rem] text-[0.6rem] font-medium text-!off-white m-auto bg-!grey-400/40 border-[2px] border-!grey-400"
                          onClick={props.onClick}
                  >
                    Coming Soon
                  </button>
                )
              }
            />
          ))
        }
      </div>

      <div className='flex flex-col mx-auto gap-y-4'>
        <div className='flex flex-row lg:text-[0.8rem] md:text-[0.8rem] text-[0.7rem] text-!grey-200 justify-center text-center lg:leading-5 leading-4  mx-auto'>
          * The plans, pricing and offering are subjected to change. We will keep you informed and any change will be applicable from next billing cycle
        </div>
      </div>
    </div>
  )
}

function Providers(props) {
  const providers = DATA.providers;

  return (
    <div className='flex flex-col lg:w-[80%] w-[90%] mx-auto gap-y-10'>
      <div className='flex flex-col mx-auto gap-y-4'>
        <div className='flex flex-row font-semibold mx-auto lg:text-5xl md:text-4xl text-3xl leading-loose'>
          <GrayGradientText text='Powered By' />
        </div>
      </div>


      <div className='grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2  mx-auto gap-4 justify-evenly'>
        {
          providers.map((provider, index) => (
            <ContentContainer
              key={index}
              id={provider.label}
              className='lg:h-[150px] sm:h-[180px] lg:w-[150px] sm:w-[180px]'
              {...provider} />
          ))
        }
      </div>
    </div>
  )
}

function CTA(props) {
  const navigate = useNavigate();

  return (
    // <div className='flex flex-col w-full my-auto gap-y-32'>
    <AuroraBackground className='flex flex-row w-full h-full'>
      <div className='flex flex-col w-[80%] m-auto gap-y-10 z-40'>
      <div className='flex flex-col mx-auto gap-y-4'>
        <div className='flex flex-row font-semibold mx-auto lg:text-5xl md:text-4xl text-3xl leading-loose'>
          <GrayGradientText text='Transform Your Interview Experience Today' />
        </div>

        <div className='flex flex-row lg:text-[1rem] md:text-[0.9rem] text-[0.8rem] text-!grey-200 justify-center text-center lg:leading-5 leading-4  mx-auto'>
          Join us now to help test our beta.
        </div>
      </div>


      <div className='flex flex-row w-fit lg:grid-cols-5 md:grid-cols-3 grid-cols-2  mx-auto gap-4 justify-evenly'>
        <GradientButton text='Join Now' onClick={() => navigate('/signup')} />
      </div>
    </div>
    </AuroraBackground>
  )
}

function LandingScreen(props) {
  return (
    <div className='flex flex-col h-full w-full gap-y-28'>
      <div className='flex min-h-screen w-full'>
        <Hero />
      </div>

      <TransitionDiv className='flex w-full mx-auto' once={true}>
        <a id='learn-more'/>
        <Platform />
      </TransitionDiv>

      <TransitionDiv className='flex w-full my-24 mx-auto' once={true}>
        <a id='roles'/>
        <Roles />
      </TransitionDiv>

      <TransitionDiv className='flex w-full my-16 mx-auto' once={true}>
        <Feature />
      </TransitionDiv>

      <TransitionDiv className='flex w-full my-16 mx-auto' once={true}>
        <Product />
      </TransitionDiv>

      <TransitionDiv className='flex w-full my-16 mx-auto' once={true}>
        <Plans />
      </TransitionDiv>

      <TransitionDiv className='flex w-full my-28 mx-auto' once={true}>
        <Providers />
      </TransitionDiv>

      <TransitionDiv className='flex min-h-screen w-full pb-12' once={true}>
        <a id='join-waitlist'/>
        <CTA />
      </TransitionDiv>

    </div>
  );
}

export default LandingScreen;
