import React from 'react';
import {twMerge} from "tailwind-merge";

function ContentContainer(props) {
  return (
    <div className={twMerge(
      'relative flex flex-col border-[0px] border-!grey-300 bg-!grey-300/30 rounded-xl gap-y-4 lg:h-[200px] md:h-[150px] h-[100px] lg:w-[200px] md:w-[150px] w-[100px] lg:p-6 md:p-4 p-2 m-auto group',
      props.className
    )}>
      <div className='flex flex-col h-full w-full m-auto justify-between z-10'>
        <div className='flex m-auto'>
          <img className='lg:h-[60px] h-[40px] opacity-60 group-hover:opacity-80' src={props.src} alt={props.label} />
        </div>

        <div className='flex flex-row lg:text-[1rem] md:text-[0.8rem] text-[0.6rem] text-white/60 group-hover:text-white/80 justify-center text-center lg:leading-5 leading-4  mx-auto'>
          {props.title}
        </div>
      </div>

      <div className='absolute hidden group-hover:block z-0 top-0 left-0 object-contain blur-xl opacity-30 transition ease-in-out duration-75'>
        <img src='/media/images/background/gradient-sq.webp' loading='lazy' alt='' />
      </div>
    </div>
  );
}

export default ContentContainer;