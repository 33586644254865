import React from 'react';

function GradientText(props) {
  return (
    <span className='flex flex-row bg-cover bg-clip-text text-transparent leading-normal bg-gradient-to-r from-!off-white via-!grey-200 to-!grey-250'>
      {props.text}
    </span>
  );
}

export default GradientText;