export const DATA = {
  roles: [
    {
      'label': 'software-engineering',
      'title': 'Software Engineering',
      'src': '/media/images/illustrations/laptop-coding.webp',
    }
  ],
  features: [
    {
      'label': 'realistic-simulation',
      'title': 'Realistic Simulation',
      'src': '/media/images/illustrations/video-chat.webp',
    },
    {
      'label': 'comprehensive-feedback',
      'title': 'Comprehensive Feedback',
      'src': '/media/images/illustrations/thumbs-up-down.webp',
    },
    {
      'label': 'resume-discussion',
      'title': 'Resume Discussion',
      'src': '/media/images/illustrations/resume.webp',
    },
    {
      'label': 'technical-evaluation',
      'title': 'Technical Evaluation',
      'src': '/media/images/illustrations/source-code.webp',
    },
    {
      'label': 'integrated-code-editor',
      'title': 'Integrated Code Editor',
      'src': '/media/images/illustrations/visual-studio.webp',
    },
    {
      'label': 'live-transcription',
      'title': 'Live Transcription',
      'src': '/media/images/illustrations/radio-waves.webp',
    },
    {
      'label': 'custom-persona',
      'title': 'Custom Persona',
      'src': '/media/images/illustrations/theatre-mask.webp',
    },
    {
      'label': 'flexible-scheduling',
      'title': 'Flexible Scheduling',
      'src': '/media/images/illustrations/schedule.webp',
    },
  ],
  providers: [
    {
      'label': 'gemini',
      'title': null,
      'src': '/media/images/bw-logos/gemini.webp',
    },
    {
      'label': 'openai',
      'title': null,
      'src': '/media/images/bw-logos/openai.webp',
    },
    {
      'label': 'mistral',
      'title': null,
      'src': '/media/images/bw-logos/mistral.webp',
    },
    {
      'label': 'llama',
      'title': null,
      'src': '/media/images/bw-logos/llama.webp',
    },
    {
      'label': 'anthropic',
      'title': null,
      'src': '/media/images/bw-logos/anthropic.webp',
    },
  ],
  plans: [
    {
      'id': 'free',
      'name': 'Trial',
      'duration': 'starting',
      'interval': null,
      'description': 'Good for trial',
      'price': 0,
      'features': [
        '2 interviews + feedback',
        'Generic Job Description',
        'Upto 1 concurrent interview',
      ],
    },
    {
      'id': 'basic',
      'name': 'Basic',
      'duration': 'month',
      'interval': 'month',
      'description': 'Good for practice',
      'price': 29,
      'features': [
        '3 interviews/month + feedbacks',
        'Custom Job Description',
        'Upto 2 concurrent interview',
        'Add more credits'
      ],
    },
    {
      'id': 'enterprise',
      'name': 'Enterprise',
      'duration': null,
      'interval': null,
      'description': 'Good for large/varied requirements',
      'price': null,
      'features': [
        'Custom interviewer persona',
        'Add company details for interview',
      ],
    },
  ],
  steps: [
    {
      src: "/media/images/background/abstract-gradient-17.webp",
      title: "Step 1",
      id: 1,
      description: "User requests to schedule an interview"
    },
    {
      src: "/media/images/background/abstract-gradient-16.webp",
      title: "Step 2",
      id: 2,
      description: "An interview is scheduled"
    },
    {
      src: "/media/images/background/abstract-gradient-15.webp",
      title: "Step 3",
      id: 3,
      description: "Candidate appears for the interview"
    },
    {
      src: "/media/images/background/abstract-gradient-14.webp",
      title: "Step 4",
      id: 4,
      description: "Feedback and scores are generated"
    },
    {
      src: "/media/images/background/abstract-gradient-13.webp",
      title: "Step 5",
      id: 5,
      description: "Appropriate actions could be taken"
    }
  ]
}