export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';
export const USER_LOGOUT_RESET = 'USER_LOGOUT_RESET';

export const USER_RETRIEVE_REQUEST = 'USER_RETRIEVE_REQUEST';
export const USER_RETRIEVE_SUCCESS = 'USER_RETRIEVE_SUCCESS';
export const USER_RETRIEVE_FAIL = 'USER_RETRIEVE_FAIL';
export const USER_RETRIEVE_RESET = 'USER_RETRIEVE_RESET';

export const USER_EMAIL_VERIFICATION_REQUEST = 'USER_EMAIL_VERIFICATION_REQUEST';
export const USER_EMAIL_VERIFICATION_SUCCESS = 'USER_EMAIL_VERIFICATION_SUCCESS';
export const USER_EMAIL_VERIFICATION_FAIL = 'USER_EMAIL_VERIFICATION_FAIL';
export const USER_EMAIL_VERIFICATION_RESET = 'USER_EMAIL_VERIFICATION_RESET';

export const OTP_SEND_REQUEST = 'OTP_SEND_REQUEST';
export const OTP_SEND_SUCCESS = 'OTP_SEND_SUCCESS';
export const OTP_SEND_FAIL = 'OTP_SEND_FAIL';
export const OTP_SEND_RESET = 'OTP_SEND_RESET';