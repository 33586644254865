import React from 'react';

function GradientText(props) {
  return (
    <span className='flex flex-row bg-cover bg-clip-text text-transparent bg-!linear-gradient-sm '>
      {props.text}
    </span>
  );
}

export default GradientText;