import React from 'react';
import {twMerge} from "tailwind-merge";

function GradientButton(props) {
  return (
    <div className="h-full w-full mx-auto">
      <button
        className={twMerge(
          "h-full w-full text-white rounded-md px-4 py-2 md:px-8 md:py-3 text-[0.7rem] md:text-[0.9rem] font-semibold m-auto bg-!gradient-4 bg-cover",
          props.className
        )}
        type={props.type || "button"}
        onClick={props.onClick}
      >
        {props.text}
      </button>
    </div>
  );
}

export default GradientButton;