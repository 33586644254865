import React, {useEffect, useRef, useState} from "react";
import { twMerge } from "tailwind-merge";
import {AnimatePresence, motion, useInView} from "framer-motion";
import {isVisible} from "@testing-library/user-event/dist/utils";

export const Radar = ({ className }) => {
  const circles = new Array(10).fill(1);

  return (
    <div
      className={twMerge(
        "relative flex h-full w-full items-center justify-center rounded-full  ",
        className
      )}
    >
      <div
        style={{
          transformOrigin: "right center",
        }}
        className="absolute right-1/2 top-1/2  z-40 flex h-[5px]
        overflow-hidden animate-radar-spin md:w-[400px] w-[300px] items-end justify-center bg-transparent"
      >
        {/* Radar line that rotates */}
        <div className="relative z-10 h-[1px] w-full bg-gradient-to-r from-transparent  via-!green-500 to-transparent" />
      </div>
      {/* concentric circles */}
      {circles.map((circle, idx) => (
        <Circle
          style={{
            height: `${(idx + 1) * 5}rem`,
            width: `${(idx + 1) * 5}rem`,
            border: `1px solid rgba(145, 151, 151, ${1 - (idx + 1) * 0.09})`,
          }}
          key={`motion-${idx}`}
          idx={idx}
        />
      ))}
    </div>
  );
};

{/* Creating circles */}
export const Circle = ({ className, children, idx,  ...rest }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true})

  return (
    <motion.div
      {...rest}
      ref={ref}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      viewport={{ once: true }}
      transition={{
        delay: idx * 0.1,
        duration: 0.2,
      }}
      className={twMerge(
        "absolute inset-0 left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 transform rounded-full border border-neutral-200",
        className
      )}
    ></motion.div>
  );
};

