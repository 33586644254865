import React, {useEffect, useState} from 'react';
import GradientButton from "../components/GradientButton";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {BackgroundCellCore} from "../components/BackgroundCellCore";
import GradientText from "../components/GradientText";

function Header(props) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className='flex flex-row w-full h-full md:px-20 sm:px-12 px-8 py-6 z-50 gap-x-14'>
      <Link to='/' className='flex flex-row w-[20%] h-full cursor-pointer z-50 '>
        <img className='sm:h-[5vh] h-[4vh] my-auto' src={'/media/images/logo/supermodal-md.webp'} alt='logo'/>
      </Link>

      {
        (location.pathname !== '/login' && location.pathname !== '/signup') && (
          <div className='flex flex-row h-full w-full gap-x-14 my-auto'>
            <div className='flex flex-row flex-1 z-50'>
              <ul className='flex flex-row list-none'>
                <li></li>
              </ul>
            </div>

            <div className='flex flex-row m-auto z-50'>
              <GradientText
                text={(
                  <Link className='font-semibold text-[18px]' to='/login'>
                    Log In
                  </Link>
                )}
              />
            </div>

            <div className='flex flex-row m-auto z-50'>
              <GradientButton
                onClick={() => navigate('/signup')}
                text='Sign Up'
              />
            </div>
          </div>
        )
      }
    </div>
  );
}

export default Header;