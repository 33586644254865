import React, {useEffect, useRef, useState} from 'react';

import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import Input from "../components/Input";
import GradientButton from "../components/GradientButton";
import {UTILS} from "../commons/utils";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {authenticate, register, sendOtp, verifyEmail} from "../store/user/userActions";
import Loader from "../components/Loader";
import Button from "../components/Button";
import {CONFIG} from "../commons/config";


function LogInForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [invalidData, setInvalidData] = useState();
  const [userEmail, setUserEmail] = useState();

  const { error, loading, user } = useSelector(state => state.authenticate);

  useEffect(() => {
    if(error) {
      const message = error.message;
      setInvalidData(message);
      toast.error(message);

      if(error.errors.email && error.errors.email.includes('unverified')) {
        navigate('/login?step=verify-otp');
      }
    } else if(user) {
      toast.success('Successfully logged in!');
      window.location.replace(CONFIG.APP_FRONTEND_URL);
    }
  }, [loading, error, user]);


  const onLogInHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);
    setUserEmail(data.email);
    props.setContext({
      email: data.email,
    })
    if(data && data.password && data.password.lenght === 0) {
      setInvalidData('Invalid password!');
      toast.error('Invalid password!');
    }
    else {
      setInvalidData(null);
    }

    dispatch(authenticate(data));
  }

  return (
    <div className='flex flex-col w-full h-fit px-10 py-12 gap-y-4 bg-!black rounded-xl'>
      <div className='flex flex-row text-3xl font-semibold'>
        Welcome back!
      </div>

      <div className='flex flex-row text-[15px] text-!grey-200'>
        We are so glad to see you again.
      </div>

      <form className='flex flex-col gap-y-4' onSubmit={onLogInHandler}>
        <Input label='Email' type='email' placeholder='jon.doe@email.com' required />
        <Input label='Password' placeholder='******' password required />

        {
          invalidData && (
            <div className='flex flex-row w-full'>
              <p className="text-[14px] text-!red-600">
                {invalidData}
              </p>
            </div>
          )
        }

        <div className='flex flex-row w-full'>
          <p className="text-[14px] text-!grey-200">
            Don't have an account?&nbsp;
            <Link to='/signup' className="font-medium text-!blue-600 hover:text-!blue-500">Sign Up here</Link>
          </p>
        </div>

        <GradientButton
          className='w-fit'
          type='submit'
          text={loading ? (
            <span className='flex flex-row gap-x-4 m-auto'>
              <Loader />
              <span className='flex flex-col'>Log In</span>
            </span>
          ) : 'Log In'}
        />
      </form>
    </div>
  )
}


function OtpForm(props) {
  const { context } = props;
  const dispatch = useDispatch();
  const [invalidData, setInvalidData] = useState();

  const inputRefs = useRef([]);

  // const { error, loading, user } = useSelector(state => state.register);
  const { error, loading, user} = useSelector(state => state.verifyEmail)

  useEffect(() => {
    if(error) {
      const message = error.message;
      setInvalidData(message);
      toast.error(message);
    } else if(user) {
      toast.success('Email verified successfully!');
      window.location.replace(CONFIG.APP_FRONTEND_URL)
    }
  }, [loading, error, user]);


  const onOtpHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);
    let otp = "";
    for(let i=0; i<6; i++) {
      otp += data[`otp-${i}`]
    }
    if(otp.length !== 6) {
      setInvalidData('Invalid OTP!');
      toast.error('Invalid OTP!');
    }
    else {
      setInvalidData(null);
    }

    dispatch(verifyEmail({
      "email": context.email,
      "otp": otp,
    }));
  }

  const onOtpInputKeyDownHandler = (e, index) => {
    const value = e.target.value;
    if(e.key === 'Backspace') {
      if(value.length > 0) return;
      else if(index > 0) {
        inputRefs.current[index-1].focus();
        e.preventDefault();
      }
    } else if(isNaN(value)) {
      e.preventDefault();
    } else {
      inputRefs.current[index].value = value;
    }
  }

  const onOtpInputKeyUpHandler = (e, index) => {
    e.preventDefault();
    const value = e.target.value;
    if(e.key === 'Backspace') return;
    if(!isNaN(value) && index < 5) {
      inputRefs.current[index+1].focus();
    }
  }

  return (
    <div className='flex flex-col w-full h-fit px-10 py-12 gap-y-4 bg-!black rounded-xl m-auto'>

      <div className='flex flex-row text-3xl font-semibold'>
        Verify Email
      </div>

      <div className='flex flex-row text-[15px] text-!grey-200'>
        We sent an OTP at your email. Please enter it below.
      </div>

      <form className='flex flex-col gap-y-6' onSubmit={onOtpHandler}>
        <div className='flex flex-col w-full gap-y-2'>
          <div className='flex flex-row w-full h-full gap-x-4 my-4'>
            {
              UTILS.range(0, 6).map(i => (
                <input
                  key={i} ref={(input) => inputRefs.current[i] = input} name={`otp-${i}`} type='text'
                  maxLength={1} pattern={'[0-9]{1}'} autoComplete='off' inputMode='numeric'
                  onKeyDown={(e) => onOtpInputKeyDownHandler(e, i)}
                  onKeyUp={(e) => onOtpInputKeyUpHandler(e, i)}
                  className='flex flex-col h-full w-full rounded-md border-[1px] border-!grey-400 bg-!grey-400/30 m-auto sm:p-4 p-3 outline-none sm:text-[2rem] text-[1.5rem] text-center'
                />
              ))
            }
          </div>

          {
            invalidData && (
              <div className='flex flex-row w-full'>
                <p className="text-[14px] text-!red-600">
                  {invalidData}
                </p>
              </div>
            )
          }
        </div>

        <div className='flex flex-row w-full'>
          <p className="text-[14px] text-!grey-200">
            Did not receive an OTP?&nbsp;
            <span className="font-medium text-!blue-600 hover:text-!blue-500 cursor-pointer">Resend</span>
          </p>
        </div>

        <div className='flex flex-row w-full items-start gap-x-2'>
          <div className='flex flex-col'>
            <GradientButton
              className='flex flex-col flex-1 w-fit justify-start'
              type='submit'
              text={loading ? (
                <span className='flex flex-row gap-x-4 my-auto'>
                <Loader />
                <span className='flex flex-col'>Verify</span>
              </span>
              ) : 'Verify'}
            />
          </div>
        </div>


      </form>
    </div>
  )
}

function LogInScreen(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [user, setUser] = useState();

  useEffect(() => {
    if(searchParams.get('step') === 'verify-otp') {
      if(!user) navigate('/login');
    }
  }, [searchParams, user]);

  return (
    <div className='flex flex-row w-full font-Inter text-white'>
      <div className='absolute inset-0 overflow-hidden z-10 h-full w-full'>
        <img
          className='object-cover w-full h-full min-h-screen'
          src='/media/images/background/abstract-gradient-2.webp'
          alt='abstract gradient background'
        />
      </div>

      <div className='flex flex-col md:px-16 sm:px-8 px-4 py-24 mx-auto md:w-[80%] lg:w-[50%] w-full z-20'>
        {
          (searchParams.get('step') === 'verify-otp' && user) ? (
            <OtpForm context={user} />
          ) : (
            <LogInForm setContext={(context) => setUser(context)} />
          )
        }
      </div>

      {/*<div className='flex flex-col h-full w-[50%]'>*/}
      {/*  <div className='absolute overflow-hidden left-0 top-0 z-10 h-full w-full'>*/}
      {/*    <img className='object-fill backdrop-blur-2xl' src='/media/images/background/abstract-gradient-6.webp'/>*/}
      {/*  </div>*/}

      {/*  <div className='flex flex-col h-full w-full z-20 px-14 py-20 gap-y-40'>*/}
      {/*    <div className='flex flex-row text-white font-bold text-6xl leading-tight'>*/}
      {/*      Welcome back!*/}
      {/*    </div>*/}

      {/*    <div className='flex flex-row text-!off-white text-2xl leading-tight italic'>*/}
      {/*      We are so glad to see you back.<br />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

export default LogInScreen;