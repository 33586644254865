import {CONFIG} from "../commons/config";
import {UTILS} from "../commons/utils";

export const USER_APIS = {
  register: (data) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: '/api/v1/users/signup/',
      method: 'POST',
      data: data
    })
  },

  authenticate: (data) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: '/api/v1/users/login/',
      method: 'POST',
      data: data
    })
  },

  retrieve: () => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/users/`,
      method: 'GET',
    })
  },

  update: () => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/users/`,
      method: 'PATCH',
    })
  },

  sendOtp: (data, config) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/users/otp/`,
      method: 'POST',
      data: data,
      ...config
    })
  },

  verifyOtp: (data, config) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/users/otp/`,
      method: 'PATCH',
      data: data,
      ...config
    })
  }
}