import React from 'react';
import {twMerge} from "tailwind-merge";

function Button(props) {
  return (
    <div className='h-full w-full m-auto'>
      {/*<img className='absolute top-0 left-0 h-full w-full z-0 rounded-md' alt='gradient' src='/media/images/background/gradient.webp' />*/}
      <button className={twMerge("h-full w-full text-white rounded-md md:px-8 px-4 py-2 md:text-[0.9rem] text-[0.6rem] font-semibold m-auto border-[1px] border-!grey-400 bg-!grey-400/60", props.className)}
              type={props.type || 'button'}
              onClick={props.onClick}
      >
        {props.text}
      </button>
    </div>
  );
}

export default Button;