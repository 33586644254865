import React from 'react';
import GradientText from "./GradientText";

function BgGradientButton(props) {
  return (
    <div className="relative h-full w-full mx-auto p-1">
      <img
        className="absolute top-0 left-0 h-full w-full rounded-md"
        alt="gradient"
        src={"/media/images/background/gradient-3.webp"}
      />
      <button
        className="relative h-full w-full text-white rounded-md px-4 py-2 md:px-8 md:py-3 text-[0.7rem] md:text-[0.9rem] font-bold m-auto bg-!off-white"
        {...props}
      >
        <div className="flex justify-center">
          <GradientText text={props.text} />
        </div>
      </button>
    </div>
  );
}

export default BgGradientButton;