import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "@redux-devtools/extension";
import {thunk} from "redux-thunk";
import {createWaitlist, updateWaitlist} from "./waitlist/waitlistReducers";
import {authenticate, register, sendOtp, verifyEmail} from "./user/userReducers";


const reducers = combineReducers({
  // user reducers
  register,
  authenticate,
  verifyEmail,
  sendOtp,

  createWaitlist,
  updateWaitlist
})

const initialState = {

}

const middlewares = [thunk]

const store = createStore(reducers, initialState,
  composeWithDevTools(applyMiddleware(...middlewares)))

export default store;