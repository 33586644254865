import {useRef} from "react";
import {motion, useScroll, useTransform} from "framer-motion";

const ScrollCarousel = ({cards, className, ...rest}) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-80%"]);

  return (
    <section ref={targetRef} className="relative md:h-[300vh] h-full">
      <div className="sticky top-1/4 md:flex hidden items-center overflow-hidden">
        {rest.title}
        <motion.div style={{ x }} className="flex gap-4 ">
          {cards.map((card) => {
            return <Card card={card} key={card.id} />;
          })}
        </motion.div>
      </div>

      <div className='md:hidden flex flex-col gap-4 px-6'>
        {cards.map((card, index) => {
          return <AnimatedCard card={card} key={card.id} delay={index * 0.1 + 0.1} />;
        })}
      </div>
    </section>
  );
};

const Card = ({ card, delay, once }) => {
  return (
    <div
      key={card.id}
      className="group relative md:h-[300px] h-[200px] md:w-[600px] w-full overflow-hidden rounded-xl "
    >
      <div
        style={{
          backgroundImage: `url(${card.src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="absolute inset-0 z-0 opacity-100 transition-transform duration-300 group-hover:scale-120"
      ></div>
      <div className="absolute inset-0 z-10 grid">
        <div className='flex flex-col gap-y-4 w-full mx-auto md:px-4 px-2 md:py-6 py-3 justify-between'>
          <div className="flex flex-row md:p-4 p-2 md:text-4xl text-2xl text-white bg-white/40 w-fit rounded-lg">
            {card.title}
          </div>

          <div className='flex flex-row text-white text-3xl py-4 px-2 font-semibold'>{card.description}</div>
        </div>
      </div>
    </div>
  );
};


const AnimatedCard = ({ card, delay, once }) => {
  return (
    <motion.div
      initial={{ opacity: 0.0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{
        delay: delay || 0.3,
        duration: 0.2,
        ease: "easeInOut",
      }}
      viewport={{once: !!once}}
      key={card.id}
      className="group relative md:h-[300px] h-[200px] md:w-[600px] w-full overflow-hidden rounded-xl "
    >
      <div
        style={{
          backgroundImage: `url(${card.src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="absolute inset-0 z-0 opacity-100 transition-transform duration-300 group-hover:scale-120"
      ></div>
      <div className="absolute inset-0 z-10 grid">
        <div className='flex flex-col gap-y-4 w-full mx-auto md:px-4 px-2 md:py-6 py-3 justify-between'>
          <div className="flex flex-row md:p-4 p-2 md:text-4xl text-2xl text-white bg-white/40 w-fit rounded-lg">
            {card.title}
          </div>

          <div className='flex flex-row text-white text-3xl py-4 px-2 font-semibold'>{card.description}</div>
        </div>
      </div>
    </motion.div>
  );
};


export default ScrollCarousel;