import React from 'react';
import {Route, Routes} from "react-router-dom";

import LandingScreen from "../screens/InterviewerLandingScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import SignUpScreen from "../screens/SignUpScreen";
import LogInScreen from "../screens/LogInScreen";

function Router(props) {
  return (
    <Routes>
      <Route path="" element={<LandingScreen />} />
      <Route path="/signup" element={<SignUpScreen />} />
      <Route path="/login" element={<LogInScreen />} />
      {/*<Route path="interviews/schedule/" element={<LandingScreen />} />*/}
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
}

export default Router;