export const CONFIG = {
  /*
   * Local Configs
   */
  // DOMAIN: 'localhost',
  // AUTH_FRONTEND_URL: 'http://localhost:3000',
  // APP_FRONTEND_URL: 'http://localhost:3001/app',
  // APP_BACKEND_URL: 'http://localhost:8000',


  /*
   * Production Configs
   */
  DOMAIN: 'cognatoai.com',
  AUTH_FRONTEND_URL: 'https://cognatoai.com',
  APP_FRONTEND_URL: 'https://cognatoai.com/app',
  APP_BACKEND_URL: 'https://sora.cognatoai.com',
}
