import {motion, useScroll, useTransform} from "framer-motion"


import React, {useRef} from 'react';
import {twMerge} from "tailwind-merge";

export function TransitionDiv(props) {

  return (
    <motion.div
      initial={{ opacity: 0.0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{
        delay: props.delay || 0.3,
        duration: props.duration || 0.2,
        ease: "easeInOut",
      }}
      viewport={{once: !!props.once}}
      className={
        twMerge('flex w-full', props.className)
      }
    >
      {props.children}
    </motion.div>
  );
}

export function ScaleDiv(props) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
  });

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const scaleDimensions = () => {
    return isMobile ? [0.7, 0.9] : [1.2, 1];
  };

  const scale = useTransform(scrollYProgress, [-1, 0.6], scaleDimensions());

  return (
    <motion.div
      ref={ref}
      className={
        twMerge( props.className)
      }
      style={{
        scale: scale,
      }}
    >
      {props.children}
    </motion.div>
  )
}