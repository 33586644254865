import React from 'react';
import {UTILS} from "../commons/utils";

function Input(props) {
  return (
    <div className='flex flex-col w-full gap-y-0'>
      <label htmlFor={props.label.toLowerCase().replaceAll(' ', '_')}
             className="flex flex-row mb-1 text-[12px] font-medium text-!grey-200 gap-x-[2px]">
        <span className='flex my-auto'>{!props.noFormatLabel ? UTILS.toTitleCase(props.label) : props.label}</span>
        {
          props.required && (
            <span className='flex flex-row my-auto text-red-600'>*</span>
          )
        }
      </label>
      <input
        type={props.type || (props.password ? 'password' : 'text')}
        name={props.label.toLowerCase().replaceAll(' ', '_')}
        id={`${props.label.toLowerCase().replaceAll(' ', '_')}`}
        placeholder={`${(props.password || props.type === 'password') ? '••••••••' : props.placeholder}`}
        className={`flex flex-row h-full w-full rounded-md border-[1px] border-!grey-400 bg-!grey-400/30 my-auto p-2 outline-none text-[0.85rem] ${props.readOnly && 'cursor-not-allowed'}`}
        defaultValue={props.defaultValue}
        required={props.required}
        hidden={props.hidden}
        {...props}
      />
    </div>
  );
}

export default Input;